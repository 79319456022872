import FeatImg from "./FeatImg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import FooterNav from "./UI/FooterNav";
import { CSSTransition } from "react-transition-group";
import { useGlobalContext } from "./hooks/GlobalContext";

const Project = ({ projectID, prllxSpeed, children }) => {
  const { setProjectID } = useGlobalContext();
  setProjectID(projectID);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 75,
      easing: "ease-in",
      distance: "30px",
    });

    return () => {
      AOS.refresh();
    };
  }, []);

  return (
    <>
      <CSSTransition in={true} appear={true} timeout={2000} classNames="page">
        <div>
          <FeatImg prllxSpeed={prllxSpeed} />
          <div className={`project`}>{children}</div>
          <FooterNav />
        </div>
      </CSSTransition>
    </>
  );
};

export default Project;
