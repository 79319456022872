import React from "react";
import Img from "./UI/Img";
import { useGlobalContext } from "./hooks/GlobalContext";
import Parallax from "react-rellax";
import ApplyCssStyles from "./UI/ApplyCssStyles";

const FeatImg = ({ prllxSpeed }) => {
  const { projectID } = useGlobalContext();

  return (
    <div className="feat-img">
      <ApplyCssStyles projectID={projectID} />
      <div className="content">
        <div className="container">
          <div className="row block justify-content-center">
            <div className="visuals col-lg-10 holder" id="feat-img-holder">
              <Parallax speed={prllxSpeed ? prllxSpeed : 4}>
                <Img
                  src="main.webp"
                  alt="Featured"
                  id="feat-img"
                  className={projectID === "4" && "mobile"}
                />
              </Parallax>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatImg;
