import { useGlobalContext } from "../hooks/GlobalContext";
import PAGES from "../Pages";

const Img = ({ src, alt, id, className, onLoad, ...otherProps }) => {
  const { projectID } = useGlobalContext();

  const projectPage = PAGES.find((page) => page.id === projectID);
  if (!projectPage) {
    return <div>Project ID not found: {projectID}</div>;
  }
  const path = "/images/" + projectPage.url + "/" + src;

  return (
    <img
      src={path}
      alt={typeof alt === "boolean" ? null : alt}
      id={id}
      className={className ? className : ""}
      // data-aos={className === "browser" ? "fade-top" : undefined}
      {...otherProps}
    />
  );
};

export default Img;
