import Img from "../UI/Img";
import Project from "../Project";
import Hi from "../Hi";
import Container from "../UI/Container";

export const ProjectAlpha = () => {
  return (
    <Project projectID="7">
      <Hi
        title="Alpha Capital Education"
        description={
          <>
            <p>
              Alpha Capital Education offers educational resources and courses
              related to finance and investment.
            </p>
            <p>
              I played a major role in the website redesign process with my
              active involvement in the Discovery & Planning and Information
              Architecture phases and was responsible for the final designs,
              ensuring those were primed for production. The design package also
              included versions optimized for mobile screens.
            </p>
          </>
        }
        skills={
          <>
            UX<em>, </em>Responsive Design<em>, </em>Photoshop
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Front End</p>
          </>
        }
      />

      <Container className="container content">
        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>01.</small>
                <h2>Landing page</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="1.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>02.</small>
                <h2>Content page</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="2.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block">
          <div className="row">
            <div className="col-lg-12">
              <div className="description">
                <small>03.</small>
                <h2>Mobile designs</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="visuals col-lg-12">
              <Img src="mobile.webp" alt="x" className="browser transparent" />
            </div>
          </div>
        </div>
      </Container>
    </Project>
  );
};
