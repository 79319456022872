import React, { createContext, useContext, useEffect, useState } from "react";
import { useGlobalContext } from "./GlobalContext";

const LoadingContext = createContext();

export const useLoadingContext = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleImageLoad = () => {
    setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
  };

  const { projectID } = useGlobalContext();

  // useEffect(() => {
  //   // setLoading(true);
  //   if (totalImages > 0 && imagesLoaded >= totalImages) {
  //     setLoading(false);
  //   }
  // }, [imagesLoaded, totalImages, projectID]);

  useEffect(() => {
    if (loading) {
      document.body.classList.add("loading");
      document.body.classList.remove("loaded");
    } else {
      document.body.classList.remove("loading");
      document.body.classList.add("loaded");
      window.scrollTo(0, 0);
    }
  }, [loading]);

  const value = {
    imagesLoaded,
    setImagesLoaded,
    totalImages,
    setTotalImages,
    handleImageLoad,
    loading,
    setLoading,
  };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};
