import { Link } from "react-router-dom/dist";
import PAGES from "./Pages";
import Parallax from "react-rellax";

const Thumbs = () => {
  return (
    <div className="row works-thumbs">
      {PAGES.map((page, index) => {
        const linkBlock = (
          <Link to={`/${page.url}`}>
            <span>
              <img src={`/images/${page.url}/thumb.webp`} alt={page.name} />
            </span>
            <small>0{page.id}.</small> {page.name}
          </Link>
        );
        return (
          <div className="col-lg-6" key={page.id}>
            {index % 2 !== 0 ? (
              <Parallax speed={3}>{linkBlock}</Parallax>
            ) : (
              linkBlock
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Thumbs;
