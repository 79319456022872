import React, { useEffect } from "react";
import { useLoadingContext } from "../hooks/LoadingContext";

const Container = ({ children }) => {
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    const containerElement = document.querySelector(".container.content");

    const handleContainerLoad = () => {
      // Container is loaded, perform your actions here
      setLoading(false);
    };

    if (containerElement && document.readyState === "complete") {
      // If the container element exists and the document is fully loaded
      handleContainerLoad();
    } else {
      // If the document is not fully loaded yet, listen for the load event
      window.addEventListener("load", handleContainerLoad);
    }

    return () => {
      // Clean up the event listener
      window.removeEventListener("load", handleContainerLoad);
    };
  }, []);

  return <div className="container content">{children}</div>;
};

export default Container;
