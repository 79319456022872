const Hi = ({ title, description, skills, involvement }) => {
  return (
    <div className="hi">
      <div className="container">
        <div className="block row">
          <h1 className="col-lg-12">{title}</h1>
          <div className="col-lg-7">
            <Description>
              {description}
              <Skills>{skills}</Skills>
            </Description>
          </div>
          <Involvement>
            <h4>Involvement</h4>
            {involvement}
          </Involvement>
        </div>
      </div>
      <Involvement />
    </div>
  );
};

export const Description = ({ children }) => {
  return <div className="description-main">{children}</div>;
};

export const Skills = ({ children }) => {
  return <p className="skills">{children}</p>;
};

export const Involvement = ({ children }) => {
  return <div className="col-lg-3 offset-lg-2 details">{children}</div>;
};

export default Hi;
