import Img from "../UI/Img";
import Project from "../Project";
import Hi from "../Hi";
import Container from "../UI/Container";

export const ProjectTgw = () => {
  return (
    <Project projectID="2">
      <Hi
        title="Bulding The Good Workshop"
        description={
          <>
            <p>
              A collaboration with an international team on designing and
              building the SAAS web app for workshop automation.
            </p>
            <p>
              My role entailed both Product Design and Front End development.
              The following screens have been captured from the production
              server.
            </p>
          </>
        }
        skills={
          <>
            {" "}
            UI/UX<em>, </em>ReactJS<em>, </em>React Bootstrap<em>, </em>
            TypeScript<em>, </em>Styled Components<em>, </em>Responsive Design
            <em>, </em>Figma
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Development</p>

            <h4>Visit</h4>
            <p>
              <a
                href="https://app.thegoodworkshop.io"
                target="_blank"
                rel="noreferrer"
              >
                app.thegoodworkshop.io
              </a>
            </p>
          </>
        }
      />

      <Container className="container content">
        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>01.</small>
                <h2>Workshop Setup</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="1.webp" alt="x" className="browser" />

            <Img src="2.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>02.</small>
                <h2>Plan & Timeline</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="3.webp" alt="x" className="browser" />
            <Img src="4.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block">
          <div className="row">
            <div className="col-lg-12">
              <div className="description">
                <small>03.</small>
                <h2>Miscelaneous</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="visuals col-lg-6">
              <Img src="5.webp" alt="x" className="browser" />
              <Img src="7.webp" alt="x" className="browser" />
              <Img src="10.webp" alt="x" className="browser" />
            </div>
            <div className="visuals col-lg-6">
              <Img src="8.webp" alt="x" className="browser" />
              <Img src="6.webp" alt="x" className="browser" />
              <Img src="9.webp" alt="x" className="browser" />
            </div>
          </div>
        </div>
      </Container>
    </Project>
  );
};
