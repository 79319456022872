import BttnToTop from "./UI/BttnToTop";

const Footer = () => {
  return (
    <>
      <BttnToTop />
    </>
  );
};

export default Footer;
