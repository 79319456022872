import { useGlobalContext } from "../hooks/GlobalContext";
import PAGES from "../Pages";
import { Link } from "react-router-dom";

const FooterNav = () => {
  const { projectID } = useGlobalContext();
  return (
    <div className="footer-nav">
      <div className="container">
        <div className="row">
          {PAGES.map((page) => (
            <div key={page.id} className="col">
              <Link
                to={`/${page.url}`}
                className={projectID === page.id ? "active" : ""}
              >
                <img src={`/images/${page.url}/thumb.webp`} alt={page.name} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
