import React from "react";
import "./assets/css/bootstrap.css";
import "./assets/css/styles.css";
import "./components/TopBar";
import TopBar from "./components/TopBar";
import MainPage from "./components/MainPage";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PAGES from "./components/Pages";
import ScrollToTop from "./components/UI/ScrollToTop";
import { LoadingProvider } from "./components/hooks/LoadingContext";

function App() {
  return (
    <div className="App">
      <LoadingProvider>
        <Router>
          <ScrollToTop />
          <header className="App-header">
            <TopBar />
          </header>
          <section className="main">
            <Routes>
              <Route path="/" element={<MainPage />} />
              {PAGES.map((page) => (
                <Route
                  key={page.id}
                  path={`/${page.url}`}
                  element={React.createElement(page.comp)}
                />
              ))}
            </Routes>
          </section>
          <Footer />
        </Router>
      </LoadingProvider>
    </div>
  );
}

export default App;
