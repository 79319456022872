import React, { useState, useEffect } from "react";

const BttnToTop = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 400) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    const scrollOptions = {
      top: 0,
      behavior: "smooth",
    };

    if ("scrollBehavior" in document.documentElement.style) {
      window.scroll(scrollOptions);
    } else {
      window.scrollBy(scrollOptions.top, scrollOptions.top);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      id="bttn-scroll-top"
      onClick={scrollToTop}
      style={{ display: showButton ? "block" : "none" }}
    >
      <i>Scroll to Top</i>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-bar-up"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </button>
  );
};

export default BttnToTop;
