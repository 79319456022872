import React, { useEffect, useState } from "react";
import PAGES from "./Pages";
import { Link } from "react-router-dom";
import NavButton from "./UI/NavButton";
import CloseButton from "./UI/CloseButton";
import LinkBack from "./UI/LinkBack";
import { useGlobalContext } from "./hooks/GlobalContext";

const TopBar = () => {
  const [topBarOpen, setTopBarOpen] = useState("");

  const toggleMenu = () => {
    if (topBarOpen === "open") {
      setTopBarOpen("closing");
      setTimeout(() => {
        setTopBarOpen("");
      }, 400);
      window.scrollTo(0, 0);
      document.body.classList.toggle("menu-open");
    } else {
      setTopBarOpen("open");
      document.body.classList.toggle("menu-open");
    }
  };

  useEffect(() => {
    showHideTopBar();
  }, []);

  function showHideTopBar() {
    const theBar = document.querySelector(".topbar");
    let didScroll = false;
    let lastScrollTop = 0;
    const delta = 5;
    const navbarHeight = theBar.offsetHeight;

    window.addEventListener("scroll", function () {
      didScroll = true;
    });

    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      if (Math.abs(lastScrollTop - st) <= delta) return;

      if (st > lastScrollTop && st > navbarHeight) {
        theBar.classList.remove("nav-down");
        theBar.classList.add("nav-up");
      } else {
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          theBar.classList.remove("nav-up");
          theBar.classList.add("nav-down");
        }
      }
      lastScrollTop = st;

      if (st <= 30) {
        theBar.classList.remove("nav-down");
      }
    }
  }

  const { projectID } = useGlobalContext();
  const projectPage = PAGES.find((page) => page.id === projectID);
  return (
    <Nav classNames={topBarOpen}>
      <div className="container">
        <div className="menu">
          <div>
            <div className="top-left">
              <Link to="/">Work</Link>
              {projectPage && (
                <>
                  {" "}
                  / <strong> {projectPage.name}</strong>
                </>
              )}
            </div>
            <div className="top-right">
              <NavButton onClick={toggleMenu} />
              <a
                href=" https://www.linkedin.com/in/oregusan/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <CloseButton onClick={toggleMenu} />
      </div>
      <div className="submenu">
        <div className="container">
          <MenuItems onClick={toggleMenu} />
          <LinkBack onClick={toggleMenu} />
        </div>
      </div>
    </Nav>
  );
};

const Nav = ({ children, classNames }) => {
  return (
    <nav className={`topbar navbar-expand-lg ${classNames}`}>{children}</nav>
  );
};

export const MenuItems = ({ onClick }) => {
  const { projectID } = useGlobalContext();

  return (
    <ul className="row">
      {PAGES.map((page, index) => (
        <li key={page.id} className="col-lg-12">
          <Link
            to={`${page.url}`}
            onClick={onClick}
            className={projectID == index + 1 ? "active" : ""}
          >
            <em></em>
            <span>
              <small>0{index + 1}</small>
              {page.name}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default TopBar;
