import { useEffect } from "react";
import PAGES from "../Pages";

const ApplyCssStyles = ({ projectID }) => {
  useEffect(() => {
    const page = PAGES.find((thepage) => thepage.id === projectID);
    if (page && page.css) {
      const styleElement = document.createElement("style");
      styleElement.textContent = `
        :root {
          --primary: ${page.css.primary};
          --bar-color: ${page.css.bar_color};
        }
        
        .feat-img {
          background-image: linear-gradient(0deg, ${page.css.grad_start} 0%, ${page.css.grad_end} 78%);
        }

        .topbar {
            background: rgba(${page.css.bar_color}, 0.7);
        }

        .topbar.open .submenu, .topbar.closing .submenu {
          background: linear-gradient(0deg, ${page.css.grad_start} 0%, ${page.css.grad_end} 78%);
        }
      `;

      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [projectID]);

  return null;
};

export default ApplyCssStyles;
