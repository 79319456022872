import Img from "../UI/Img";
import Project from "../Project";
import Hi from "../Hi";
import Container from "../UI/Container";

export const ProjectMs = () => {
  return (
    <Project projectID="6">
      <Hi
        title="Marks & Spencer"
        description={
          <>
            <p>
              A project completed for a major British retailer - a website for
              the Christmas offering. The main tasks included creating smooth
              experience of choosing products for the menu and placing the
              order, within existing technical and logistical constraints.
            </p>
          </>
        }
        skills={
          <>
            {" "}
            UX Design<em>, </em>Prototyping<em>, </em>Sketch
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Prototyping</p>
          </>
        }
      />

      <Container className="container content">
        <div className="block">
          <div className="row">
            <div className="col-lg-12">
              <div className="description">
                <small>01.</small>
                <h2>Checkout - Wireframes</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="visuals col-lg-12">
              <Img src="8.webp" alt="x" className="browser" />
            </div>

            <div className="visuals col-lg-12">
              <Img src="9.webp" alt="x" className="browser" />
            </div>
          </div>
        </div>

        <div className="block">
          <div className="row">
            <div className="col-lg-12">
              <div className="description">
                <small>02.</small>
                <h2>Menu - Design Explorations</h2>
              </div>
            </div>
          </div>

          <div className="row masonry-container">
            <div className="visuals col-lg-6 masonry-item">
              <Img src="101.webp" alt="x" className="browser" />
            </div>
            <div className="visuals col-lg-6 masonry-item">
              <Img src="102.webp" alt="x" className="browser" />
            </div>
          </div>
        </div>

        <div className="block">
          <div className="row">
            <div className="col-lg-12">
              <div className="description">
                <small>03.</small>
                <h2>Miscelaneous</h2>
              </div>
            </div>
          </div>

          <div className="row masonry-container">
            <div className="visuals col-lg-6 masonry-item">
              <Img src="7.webp" alt="x" className="browser" />
            </div>
            <div className="visuals col-lg-6 masonry-item">
              <Img src="10.webp" alt="x" className="browser" />
            </div>
            <div className="visuals col-lg-6 masonry-item">
              <Img src="11.webp" alt="x" className="browser" />
            </div>
          </div>
        </div>
      </Container>
    </Project>
  );
};
