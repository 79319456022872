import Img from "../UI/Img";
import Project from "../Project";
import Hi from "../Hi";
import Parallax from "react-rellax";
import Container from "../UI/Container";

export const ProjectIspma = () => {
  return (
    <Project projectID="1">
      <Hi
        title="Relaunch of ISPMA.ORG"
        description={
          <>
            <p>
              Complete redesign and redevelopment of the main online presense of
              International Software Product Management Association (ISPMA).
            </p>
          </>
        }
        skills={
          <>
            Responsive Design<em>, </em>HTML<em>, </em>SASS<em>, </em>
            Bootstrap<em>, </em>Javascript<em>, </em>PHP
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Development</p>
            <p>Wordpress</p>

            <h4>Visit</h4>
            <p>
              <a href="https://ispma.org/" target="_blank" rel="noreferrer">
                ispma.org
              </a>
            </p>
          </>
        }
      />

      <Container className="container content">
        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>01.</small>
                <h2>Homepage</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="homepage.webp" alt="homepage" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>02.</small>
                <h2>Fellows</h2>

                <p>
                  A Wordpress' custom post type, that holds relevant information
                  about the company's fellows. Accompanied with a custom-built
                  on-page search functionality.
                </p>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="8-4.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>03.</small>
                <h2>Events</h2>
                <p>Another custom post type.</p>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="events.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>04.</small>

                <h2>Integrated data</h2>
                <p>
                  Taking advantage of PHP tabular data import for displaying
                  members' directory, glossary, bibliography, etc. Reusing the
                  on-page search with optional filters.
                </p>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="/certified.webp" alt="x" className="browser" />
            <Img src="/glossary.webp" alt="x" className="browser" />
            <Img src="/resources-2.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <small>05.</small>

              <h2>On page search</h2>
              <p>
                Utilizing the WordPress REST API to deliver dynamic on-site
                search results, and enhancing the user experience through the
                visual categorization of search results.
              </p>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="search.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>06.</small>

                <h2>Body of Knowledge Framework</h2>
                <p>
                  Using custom post type to organize crucial information and
                  creating dynamic and responsive interface.
                </p>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="bok.webp" alt="x" className="browser" />
            <Img src="bok-2.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>07.</small>

                <h2>Blog section</h2>
                <p>
                  Native to the WP platform, with expanded functionality and
                  enhanced data interconnections.
                </p>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="5.webp" alt="x" className="browser" />
            <Img src="7-2.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block">
          <div className="row">
            <div className="col-lg-12">
              <div className="description">
                <small>08.</small>
                <h2>Miscelaneous</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="visuals col-lg-6">
              = <Img src="9-1.webp" alt="x" className="browser" />
              <Img src="10.webp" alt="x" className="browser" />
              <Img src="11-2.webp" alt="x" className="browser" />
              <Img src="12.webp" alt="x" className="browser" />
            </div>
            <div className="visuals col-lg-6">
              <Parallax speed={-0.1}>
                <Img src="events-2.webp" alt="x" className="browser" />

                <Img src="resources.webp" alt="x" className="browser" />

                <Img src="subscribe.webp" alt="x" className="browser" />

                <Img src="faq.webp" alt="x" className="browser" />

                <Img src="15.webp" alt="x" className="browser" />
              </Parallax>
            </div>
          </div>
        </div>
      </Container>
    </Project>
  );
};
