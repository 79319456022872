import { ProjectIspma } from "./Projects/Ispma";
import { ProjectTgw } from "./Projects/Tgw";
import { ProjectTgwLanding } from "./Projects/TgwLanding";
import { ProjectSocii } from "./Projects/Socii";
import { ProjectCurDir } from "./Projects/CurDir";
import { ProjectMs } from "./Projects/Ms";
import { ProjectAlpha } from "./Projects/Alpha";

const PAGES = [
  {
    id: "1",
    name: "ISPMA",
    url: "ispma",
    comp: ProjectIspma,
    css: {
      primary: "#f0193d",
      grad_start: "#c80096",
      grad_end: "#1c3696",
      bar_color: "28, 54, 150",
    },
  },
  {
    id: "2",
    name: "The Good Workshop",
    url: "tgw",
    comp: ProjectTgw,
    css: {
      primary: "#18d2ac",
      grad_start: "#18d2ac",
      grad_end: "#5195fb",
      bar_color: "81, 149, 251",
    },
  },
  {
    id: "3",
    name: "The Good Workshop (landing)",
    url: "tgw-landing",
    comp: ProjectTgwLanding,
    css: {
      primary: "#18d2ac",
      grad_start: "#18d2ac",
      grad_end: "#5195fb",
      bar_color: "81, 149, 251",
    },
  },
  {
    id: "4",
    name: "Socii Money",
    url: "socii",
    comp: ProjectSocii,
    css: {
      primary: "#60064c",
      grad_end: "#f98b83",
      grad_start: "#60064c",
      bar_color: "249, 139, 131",
    },
  },
  {
    id: "5",
    name: "Currencies Direct",
    url: "currencies-direct",
    comp: ProjectCurDir,
    css: {
      primary: "#d86836",
      grad_end: "#d86836",
      grad_start: "#d41a32",
      bar_color: "216, 104, 54",
    },
  },
  {
    id: "6",
    name: "Marks & Spencer",
    url: "ms",
    comp: ProjectMs,
    css: {
      primary: "#a58959",
      grad_end: "#2a2826",
      grad_start: "#a58959",
      bar_color: "42, 40, 38",
    },
  },
  {
    id: "7",
    name: "Alpha Capital Education",
    url: "alpha",
    comp: ProjectAlpha,
    css: {
      primary: "#738ea9",
      grad_end: "#738ea9",
      grad_start: "#006581",
      bar_color: "115, 142, 169",
    },
  },
];

export default PAGES;
